import styled, { css } from 'styled-components'
import {
  type InputElementProps,
  type HelperTextProps,
} from '@/elements/Inputs/types'
import { fontWeights } from '@/styles/typography/constants'
import { Body2 } from '@/styles/typography'
import { TransparentButtonV2 } from '@/elements/Buttons'

// This is only intended to be used within Inputs.  All other typography elements should use components found in `styles/typography`
export const Body1FontStyles = css`
  /* Font Styles for Body 1 */
  font-weight: ${fontWeights.book};
  line-height: 1.5rem;
  font-size: 1.0625rem;
`

export const InputElement = styled.input.withConfig({
  /* eslint-disable @typescript-eslint/consistent-type-assertions -- passwordrules is a Safari-only property at the moment, and not part of the regular typings for input elements */
  shouldForwardProp: (prop, defaultValidatorFn) =>
    defaultValidatorFn(prop) || (prop as string) === 'passwordrules',
  /* eslint-enable @typescript-eslint/consistent-type-assertions -- re-enable rule*/
})<InputElementProps>`
  width: 100%;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.gray900};
  caret-color: ${({ hasError, theme }) =>
    hasError ? theme.red500 : theme.plum};

  &:disabled {
    color: ${({ theme }) => theme.gray400};
  }

  &:focus-within::placeholder {
    color: ${({ theme }) => theme.gray400};
  }

  ::placeholder {
    color: ${({ theme }) => theme.gray600};
  }

  ${Body1FontStyles}
`

export const InputContainer = styled.div`
  width: 100%;
`

export const HelperMessagesGroup = styled.div<{
  shouldRemoveHelperTextGroupMargin?: boolean
}>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'start end';
  gap: 16px;

  &:empty {
    margin-bottom: ${({ shouldRemoveHelperTextGroupMargin }) =>
      !shouldRemoveHelperTextGroupMargin && '24px'};
  }
`

export const HelperText = styled(Body2)<HelperTextProps>`
  margin-top: 0.25rem;
  grid-area: ${({ positionEnd = false }) => (positionEnd ? 'end' : 'start')};
  color: ${({ theme, hasError, surface = 'default' }) =>
    hasError
      ? surface === 'default'
        ? theme.red500
        : theme.red400
      : theme.gray600};
`

export const InputButton = styled(TransparentButtonV2)`
  display: flex;
  align-items: center;
  z-index: 2;
`
