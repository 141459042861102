import { useCallback, useRef, useState, useMemo, useId, type JSX } from 'react'
import { getKey, keys } from '@/constants/keyCodes'
import { isStandardCharacterKeyEvent } from '@/utils/keyEvents'
import { useDetectFocusOutside } from '@/hooks/useDetectFocusOutside'

const isUndefined = <T>(val: T) => typeof val === 'undefined'
const identity = <T>(val: T) => val
const defaultItemToString = <T>(item: T) => (item ? String(item) : '')

export type OptionProperties = Pick<
  JSX.IntrinsicElements['li'],
  'role' | 'id' | 'aria-selected'
> & { onClick: () => void }

export type OnComboboxItemArgs = { suggestion?: boolean; index?: number }
type Props<T> = {
  items: T[]
  getItem?: typeof identity
  id?: string
  inputValue: string
  inputOnChange: (val: string) => void
  inputOnFocus: (e: React.FocusEvent<HTMLInputElement>) => void
  inputOnBlur: () => void
  onItemEngagement?: (item: T, index: number) => void
  onSelect: (item: string, args: OnComboboxItemArgs) => void
  initiallyExpanded?: boolean
  initiallySelected?: boolean
  autocomplete?: boolean
  userTypedQuery?: string
  loop?: boolean
  itemToString?: (item: T | string) => string
  onSetIsExpanded?: (isExpanded: boolean) => void
}

export const useCombobox = <T, TRef extends HTMLElement>({
  items = [],
  getItem = identity,
  id,
  inputValue,
  inputOnChange,
  inputOnFocus,
  inputOnBlur,
  onSelect,
  onItemEngagement,
  initiallyExpanded = false,
  initiallySelected = false,
  autocomplete = false,
  userTypedQuery = '',
  itemToString = defaultItemToString,
  loop = false,
  onSetIsExpanded,
}: Props<T>) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [activeIndex, setActiveIndex] = useState<undefined | number>(undefined)
  const [internalInputValue, setInternalInputValue] = useState('')
  const comboboxId = useId()

  const handleSetIsExpanded = useCallback(
    (expanded: boolean) => {
      setIsExpanded(expanded)
      onSetIsExpanded?.(expanded)
    },
    [onSetIsExpanded]
  )

  const accessibleId = useMemo(() => {
    if (!id) {
      return comboboxId
    }
    return id
  }, [id, comboboxId])

  const value = inputValue || internalInputValue
  const { length } = items

  const setInputValue = (v: T | string) => {
    const val = itemToString(v)
    if (isUndefined(inputValue)) {
      setInternalInputValue(val)
    }
    inputOnChange(val)
  }

  const handleOnSelect = (v: T | string, args: OnComboboxItemArgs) =>
    onSelect(itemToString(v), args)

  const engageItem = (index: number | undefined, clicked = false) => {
    if (typeof index === 'number') {
      const s = getItem(items[index])
      if (autocomplete || clicked) {
        setInputValue(s)
      }
      setActiveIndex(index)
      onItemEngagement?.(s, index)
      if (clicked) {
        handleOnSelect(s, { suggestion: true, index })
        handleSetIsExpanded(false)
      }
    } else {
      setInputValue(userTypedQuery)
    }
  }

  const textboxRef = useRef<HTMLInputElement>(null)
  const wrapperRef = useRef<TRef>(null)

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    inputOnFocus(e)
    handleSetIsExpanded(initiallyExpanded)
    setActiveIndex(initiallyExpanded && initiallySelected ? 0 : undefined)
  }

  // wrapped in useCallback to keep event listeners
  // from constantly cycling in useDetectFocusOutside
  const handleBlur = useCallback(() => {
    inputOnBlur()
    handleSetIsExpanded(false)
    setActiveIndex(undefined)
  }, [handleSetIsExpanded, inputOnBlur])

  useDetectFocusOutside(wrapperRef, handleBlur, isExpanded)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isUndefined(inputValue)) {
      setInternalInputValue(e.target.value)
    }
    inputOnChange(e.target.value)
  }

  const handleOptionClick = (index: number) => {
    engageItem(index, true)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (getKey(e)) {
      case keys.ARROW_DOWN:
        e.preventDefault()
        if (!isExpanded) handleSetIsExpanded(true)
        if (isUndefined(activeIndex)) {
          engageItem(0)
        } else {
          engageItem((Number(activeIndex) + 1) % length)
        }
        break
      case keys.ARROW_UP:
        e.preventDefault()
        if (!isExpanded) handleSetIsExpanded(true)
        if (isUndefined(activeIndex)) {
          engageItem(length - 1)
        } else {
          if (activeIndex === 0) {
            if (loop) {
              setActiveIndex(length - 1)
              engageItem(length - 1)
            } else {
              setActiveIndex(undefined)
              engageItem(undefined)
            }

            textboxRef.current?.setSelectionRange(value.length, value.length)
          } else {
            engageItem(Number(activeIndex) - 1)
          }
        }
        break
      case keys.ESCAPE:
        e.preventDefault()
        if (isExpanded) {
          handleSetIsExpanded(false)
          setActiveIndex(undefined)
          setInputValue('')
        }
        break
      case keys.ARROW_RIGHT:
      case keys.ARROW_LEFT:
        setActiveIndex(undefined)
        break
      case keys.HOME:
        e.preventDefault()
        setActiveIndex(undefined)
        textboxRef.current?.setSelectionRange(0, 0)
        break
      case keys.END:
        e.preventDefault()
        setActiveIndex(undefined)
        textboxRef.current?.setSelectionRange(value.length, value.length)
        break
      case keys.ENTER:
        e.preventDefault()
        if (isUndefined(activeIndex)) {
          handleOnSelect(value, { suggestion: false })
        } else {
          const selectedVal = getItem(items[Number(activeIndex)])
          setInputValue(selectedVal)
          handleOnSelect(selectedVal, { suggestion: true, index: activeIndex })
        }
        setActiveIndex(undefined)
        handleSetIsExpanded(false)
        break
      default:
        if (!isExpanded) handleSetIsExpanded(true)
        if (isStandardCharacterKeyEvent(e)) {
          setActiveIndex(undefined)
        }
    }
  }

  const getWrapperProperties = () => ({
    ref: wrapperRef,
  })

  const getContainerProperties = () => ({
    role: 'combobox',
    'aria-haspopup': 'listbox' as const,
    'aria-owns': `${accessibleId}-listbox`,
    'aria-expanded': isExpanded,
    id: `${accessibleId}-combobox`,
  })

  const getInputProperties = () => ({
    id: `${accessibleId}-textbox`,
    'aria-autocomplete': autocomplete ? ('both' as const) : ('list' as const),
    ...(isExpanded && { 'aria-controls': `${accessibleId}-listbox` }),
    ref: textboxRef,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onFocus: handleFocus,
    value,
    ...(isExpanded &&
      !isUndefined(activeIndex) && {
        'aria-activedescendant': `${accessibleId}-listbox-item-${activeIndex}`,
      }),
  })

  const getListboxProperties = () => ({
    role: 'listbox',
    id: `${accessibleId}-listbox`,
  })

  const getOptionProperties = (index: number): OptionProperties => ({
    role: 'option',
    id: `${accessibleId}-listbox-item-${index}`,

    onClick: () => {
      handleOptionClick(index)
    },

    ...(index === activeIndex && { 'aria-selected': true }),
  })

  return {
    handleBlur,
    textboxRef,
    isExpanded,
    activeIndex,
    getWrapperProperties,
    getContainerProperties,
    getInputProperties,
    getListboxProperties,
    getOptionProperties,
  }
}
